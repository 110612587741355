import React from "react";
import "../App.css";

const NotFound = () => {
  return (
    <div className="App">
      <div className="App-wrapper">
        <div id="dashboard-wrapper">404: Page not found</div>
      </div>
    </div>
  );
};

export default NotFound;
